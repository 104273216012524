// Custom JS Application Code
function validate_email_js(email) { var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; if(reg.test(email) == false) { return false; } return true; }
var ellipsisText = function (e, etc) { var wordArray = e.innerHTML.split(" "); while (e.scrollHeight > e.offsetHeight) { wordArray.pop(); e.innerHTML = wordArray.join(" ") + (etc || "..."); } };

function checkHeaderOffset() {
	var $window = $(window);
	var offset = $window.scrollTop();
	var headerHeight = $('.header').outerHeight();
	var headerImages = $('.header-images');
	var headerImageHeight = $('.header-images .img-1 img').height();
	var offsetCompareMobile = headerHeight+47;
	//var offsetCompareDesktop = headerHeight+126;
	var offsetCompareDesktop = headerHeight+26;
	var navOffsetTop = $('.nav-wrapper').offset().top;
	var logoHit = false;
	//console.log(offsetCompareMobile);
	if (offset>=30) {
		$('#headerWrapper').addClass('scrolled');
	}else{
		$('#headerWrapper').removeClass('scrolled');
	}
	/*
	if (browserWidth<992) {
		if (offset>=215-offsetCompareMobile) {
			$('.nav-wrapper').css('top',(headerHeight+47)+'px');
			$('.nav-wrapper').addClass('fixed');
		}else{
			$('.nav-wrapper').removeClass('fixed');
			$('.nav-wrapper').css('top','');
		}
	}else{
		if (offset>=746-offsetCompareDesktop) {
			$('.nav-wrapper').css('top',(headerHeight+126)+'px');
			$('.nav-wrapper').addClass('fixed');
		}else{
			$('.nav-wrapper').removeClass('fixed');
			$('.nav-wrapper').css('top','');
		}
	}
	*/
	if (browserWidth>=992) {
		if (offset>=746-offsetCompareDesktop) {
			//$('body').css('background-color','red');
			logoHit = true;
			$('#headerWrapper').addClass('logo-hit');
			//lottiePlayer.setDirection(-1);
			//lottiePlayer.play();
		}else{
			logoHit = false;
			$('#headerWrapper').removeClass('logo-hit');
			//lottiePlayer.setDirection(1);
			//lottiePlayer.play();
		}
	} else {
		if (offset>=215-offsetCompareMobile) {
			logoHit = true;
			$('#headerWrapper').addClass('logo-hit');
			//lottiePlayer.setDirection(-1);
			//lottiePlayer.play();
		}else{
			logoHit = false;
			$('#headerWrapper').removeClass('logo-hit');
			//lottiePlayer.setDirection(1);
			//lottiePlayer.play();
		}
	}
	if (browserWidth<992) {
		if (215-18<offset) {
			$('.nav-wrapper').addClass('fixed');
		}else{
			$('.nav-wrapper').removeClass('fixed');
		}
	}else{
		if (746-24<offset) {
			$('.nav-wrapper').addClass('fixed');
		}else{
			$('.nav-wrapper').removeClass('fixed');
		}

	}

	headerImages.css('top',headerHeight+(browserWidth<1024 ? (browserWidth<820 ? 16 : 26) : 44)+'px');
}

function windowOnResize() {
	var myWidth, myHeight;
	if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
	else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
	else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
	browserWidth = myWidth;
	browserHeight = myHeight;
	checkHeaderOffset();
}
function pageScroll() {
	checkHeaderOffset();
	checkMainNavigation();
}

function checkMainNavigation() {
	var $window = $(window);
	var offset = $window.scrollTop();
	var pageSubnavElem = $();
	var currentItem = 0;
	var list = $('.main-nav');
	var pageNavItems = $('.homepage-wrapper section.section-scroll');
	var browserHalf = parseInt((browserHeight/4),10)*3;

	pageNavItems.each(function() {
		var currentElemTop = $(this).offset().top;
		if (currentElemTop<offset+browserHalf) { currentItem++; }
	});

	list.find('li > a').removeClass('active');

	if (currentItem>0) {
		list.find('li').eq(currentItem-1).find('a').addClass('active');
	}
}


function scrollToSlide(slideTo) {
	$('body,html').animate({ scrollTop: $('.'+slideTo).offset().top-$('header').height() }, 800);
}
(function($) {
	"use strict"; // Start of use strict
	$(window).bind('hashchange');
	$(window).trigger('hashchange');
	$(window).scroll( function() { pageScroll(); } );
	$(window).resize(function() { windowOnResize(); });
	$(window).ready(function() {
		var myWidth, myHeight;
		if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
		else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
		else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
		browserWidth = myWidth;
		browserHeight = myHeight;


		//if ($(window).width()>=1024) { new WOW().init(); }

		//$('.go-top a').click(function () { $('body,html').animate({ scrollTop: 0 }, 800); $(this).blur(); return false; });
		/*
		$('.main-nav ul li a').each(function() {
			var thisHref = $(this).attr('href');
			if (/#/.test(thisHref)) {
				if (!js_vars.vars.isFrontPage) {
					$(this).attr('href',js_vars.vars.siteurl+'/'+thisHref);
				}
			}
		});
		*/

		$('.nav-toggle').bind('click',function(ev) {
			if ($('.nav-wrapper').hasClass('opened')) {
				$('.nav-wrapper').fadeOut(0,function() {
					$(this).removeClass('opened');
					$('#headerWrapper').removeClass('opened');
				});
				$(this).removeClass('is-active');
			}else{
				$('#headerWrapper').addClass('opened');
				$('.nav-wrapper').fadeIn(0,function() {
					$(this).addClass('opened');
				});
				$(this).addClass('is-active');
			}

			$(this).blur();
			ev.preventDefault();
		});

		$('.main-nav li a').bind('click',function(ev) {
			var thisHref = $(this).attr('href');
			if (thisHref.indexOf("#") != -1) {
				var hash = thisHref.substr(thisHref.indexOf("#"));
				$('body,html').animate({ scrollTop: $(hash).offset().top-(browserWidth<992 ? 200 : 150) }, 800);
			}
			$(this).blur(); ev.preventDefault();
		});

	});
	$(window).on('load',function() {
		//checkHeaderOffset();
		lottiePlayer = document.querySelector("lottie-player");
		lottiePlayer.addEventListener("complete", () => {
			$('.header').addClass('animation-complete');
			//lottiePlayer.setSpeed(5);
		});
		if ($('.hp-banners').length) {
			$('.hp-banners').each(function() {
				new Swiper('.hp-banners .swiper-container', {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					autoHeight: false,
					autoplay: {
						delay: 3000,
					},
				});
			});
		}
		if ($('.hp-artists').length) {
			$('.hp-artists').each(function() {
				new Swiper('.hp-artists .swiper-container', {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					autoHeight: false,
					autoplay: {
						delay: 5000,
					},
					pagination: {
						el: '.hp-artists .swiper-custom-pagination',
						type: 'bullets',
						clickable: true
					},
				});
			});
		}
		if ($('.hp-episodes').length) {
			$('.hp-episodes').each(function() {
				new Swiper('.hp-episodes .swiper-container', {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					autoHeight: false,
					autoplay: {
						delay: 3000,
					},
					pagination: {
						el: '.hp-episodes .swiper-custom-pagination',
						type: 'bullets',
						clickable: true
					},
				});
			});
		}
		if ($('.hp-merch').length) {
			$('.hp-merch').each(function() {
				new Swiper('.hp-merch .swiper-container', {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					autoHeight: false,
					autoplay: {
						delay: 3000,
					},
					pagination: {
						el: '.hp-merch .swiper-custom-pagination',
						type: 'bullets',
						clickable: true
					},
				});
			});
		}
	});

})(jQuery); // End of use strict

window.addEventListener('DOMContentLoaded', (event) => {
	gsap.registerPlugin(ScrollTrigger);
});
window.addEventListener('DOMContentLoaded', (event) => {
	(function($) {
		"use strict"; // Start of use strict
		$(window).on('load',function() {
			ScrollTrigger.matchMedia({
				"(max-width: 819px)": function() {
					//$('.hp-artists.in-desktop')
					var appearItems = gsap.utils.toArray('[data-appear]');
					appearItems.forEach((item) => {
						gsap.set(item, { css: { y: 30, opacity: 0 } });
					});
					appearItems.forEach((item) => {
						var appearDelay = (item.hasAttribute('data-appear-delay') ? item.getAttribute('data-appear-delay') : "0");
						var tl = gsap.timeline().paused(true);
						tl.to(item, { y: 0, opacity: 1, ease: "linear", duration: 0.5 },"+="+appearDelay);

						ScrollTrigger.create({
							animation: tl,
							trigger: item,
							start: 'top 100%'
						});
					});
				},
				"(min-width: 820px)": function() {
					//$('.hp-artists.in-desktop')
					var appearItems = gsap.utils.toArray('[data-appear]');
					appearItems.forEach((item) => {
						gsap.set(item, { css: { y: 30, opacity: 0 } });
					});
					appearItems.forEach((item) => {
						var appearDelay = (item.hasAttribute('data-appear-delay') ? item.getAttribute('data-appear-delay') : "0");
						var tl = gsap.timeline().paused(true);
						tl.to(item, { y: 0, opacity: 1, ease: "linear", duration: 0.5 },"+="+appearDelay);

						ScrollTrigger.create({
							animation: tl,
							trigger: item,
							start: 'top 100%'
						});
					});
				},
				"(min-width: 1024px)": function() {

					//$('.hp-artists.in-desktop')
					var appearItems = gsap.utils.toArray('[data-appear]');
					appearItems.forEach((item) => {
						gsap.set(item, { css: { y: 30, opacity: 0 } });
					});
					appearItems.forEach((item) => {
						var appearDelay = (item.hasAttribute('data-appear-delay') ? item.getAttribute('data-appear-delay') : "0");
						var tl = gsap.timeline().paused(true);
						tl.to(item, { y: 0, opacity: 1, ease: "linear", duration: 0.5 },"+="+appearDelay);

						ScrollTrigger.create({
							animation: tl,
							trigger: item,
							start: 'top 100%'
						});
					});

				},
				"all": function() {},
			});
		});
	})(jQuery); // End of use strict
});

var browserWidth = 0;
var browserHeight = 0;
var navInitialOffset = 0;
var lottiePlayer;
